<template>
  <el-card class="box-card business full-screen">
    <div ref="pageHead" class="table-page-head">
      <el-row class="table-btns">
        <el-button type="text" icon="el-icon-edit" @click="editRole()" size="mini" class="text-btn">{{$t('addBusiness')}}</el-button>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="businessList" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" default-expand-all v-loading="isloading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <!-- <el-table-column prop="id" label="ID" width="60"></el-table-column> -->
        <el-table-column fixed="left" prop="name" :label="$t('name')" width="300">
          <template slot-scope="scope">
            {{$t(scope.row.name)}}
          </template>
        </el-table-column>
        <el-table-column fixed="left" prop="businessCode" :label="$t('code')" width="80"></el-table-column>
        <el-table-column prop="type" :label="$t('level')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.type==0" class="text-primary">{{$t('mainBusiness')}}</span>
            <span v-if="scope.row.type==1" class="text-success">{{$t('childBusiness')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="businessType" :label="$t('dealType')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.businessType==1" class="text-success">{{$t('internalDeal')}}</span>
            <span v-if="scope.row.businessType==2" class="text-danger">{{$t('externalDeal')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="haveRemuneration" :label="$t('remuneration')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.haveRemuneration==0" class="text-success">{{$t('have')}}</span>
            <span v-else class="text-danger">{{$t('notHave')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="haveServiceCharge" :label="$t('serviceCharge')" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.haveServiceCharge==0" class="text-success">{{$t('have')}}</span>
            <span v-else class="text-danger">{{$t('notHave')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="parentId" :label="$t('parentNode')" width="160">
          <template slot-scope="scope">
            {{$t(scope.row.parentId==0?'':primaryService.find(function(t){return t.id==scope.row.parentId}).name)}}
          </template>
        </el-table-column>
        <el-table-column prop="urlLink" :label="$t('routeName')" min-width="220"></el-table-column>
        <el-table-column prop="remark" :label="$t('remark')" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" :label="$t('creationTime')" width="200">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
        <el-table-column :label="$t('extend')" fixed="right" width=" 80">
          <template slot-scope="scope">
            <el-button size="mini" @click="editRole(scope.row)" type="primary">{{$t('edit')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer :title="$t('editBusiness')" :visible.sync="editRoleDrawer" :direction="'rtl'" :modal="false" :destroy-on-close="true" :wrapperClosable="false" :size="500">
      <el-form :model="businessForm" :rules="rules" ref="businessForm" label-position="top">
        <el-form-item :label="$t('name')" prop="name">
          <el-input size="medium" v-model="businessForm.name" />
        </el-form-item>
        <el-form-item :label="$t('level')" prop="type">
          <el-select size="medium" v-model="businessForm.type" style="width:100%">
            <el-option :label="$t('mainBusiness')" :value="0"></el-option>
            <el-option :label="$t('childBusiness')" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <!-- 子业务显示以下表单 -->
        <div v-if="businessForm.type==1">
          <el-form-item :label="$t('parentNode')" prop="parentId">
            <el-select size="medium" v-model="businessForm.parentId" style="width:100%">
              <el-option v-for="(t,i) in primaryService" :label="$t(t.name)" :value="t.id" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('code')" prop="businessCode">
            <el-input size="medium" v-model="businessForm.businessCode" />
          </el-form-item>
          <el-form-item :label="$t('serviceCharge')" prop="haveServiceCharge">
            <el-select size="medium" v-model="businessForm.haveServiceCharge" style="width:100%">
              <el-option :label="$t('have')" :value="0"></el-option>
              <el-option :label="$t('notHave')" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('remuneration')" prop="haveRemuneration">
            <el-select size="medium" v-model="businessForm.haveRemuneration" style="width:100%">
              <el-option :label="$t('have')" :value="0"></el-option>
              <el-option :label="$t('notHave')" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('dealType')" prop="businessType">
            <el-select size="medium" v-model="businessForm.businessType" style="width:100%">
              <el-option :label="$t('internalDeal')" :value="1"></el-option>
              <el-option :label="$t('externalDeal')" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('routeName')" prop="urlLink">
            <el-input size="medium" v-model="businessForm.urlLink" />
          </el-form-item>
        </div>
        <el-form-item :label="$t('remark')">
          <el-input size="medium" v-model="businessForm.remark" />
        </el-form-item>

        <el-form-item class="bottom-button">
          <el-button v-if="businessForm.id" size="medium" type="primary" @click="updateSubmit('businessForm')" :loading="isloading">{{$t('submit')}}</el-button>
          <el-button v-else size="medium" type="primary" @click="addSubmit('businessForm')" :loading="isloading">{{$t('submit')}}</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>
<script>
import business from "@/api/business";
import verification from "@/assets/uilt/verification"
export default {
  name: "businessManaged",
  data () {
    return {
      queryForm: {},
      businessForm: { type: 0 },
      //table高度，动态设置
      queryTableHeight: "",
      //table显示内容
      businessList: [],
      //所有父节点
      primaryService: [],
      //显示加载中
      isloading: false,
      //新增\编辑角色
      editRoleDrawer: false,
      //设置角色权限
      editJurisdictionDrawer: false,
    };
  },
  computed: {
    rules () {
      let verify = new verification(this);
      return {
        name: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        type: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        parentId: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        businessCode: [{ validator: verify.numberCode2, required: true, trigger: 'blur' },],
        haveServiceCharge: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        haveRemuneration: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        businessType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        urlLink: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  watch: {},
  methods: {
    getBusinessList () {
      let _this = this;
      business.getList({
        success: res => {
          if (res.code != 200) return;
          let tree = res.data.filter(function (t) { return t.parentId === 0 })
          for (let i = 0; i < tree.length; i++) {
            tree[i].children = res.data.filter(function (t) { return t.parentId === tree[i].id })
          }
          _this.businessList = tree.sort(function (a, b) { return a.id - b.id });

          _this.primaryService = JSON.parse(JSON.stringify(res.data.filter(function (t) { { return t.parentId == 0 } })));
        }
      })
    },
    //其它操作
    editRole (data) {
      let _this = this;

      _this.businessForm = { type: 0 };
      if (data) _this.businessForm = data;
      _this.editRoleDrawer = true;
    },

    addSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          _this.businessForm.parentId = _this.businessForm.parentId || 0;
          business.add({
            param: _this.businessForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getBusinessList();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
    updateSubmit (formName) {
      let _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.isloading = true;
          business.updateById({
            param: _this.businessForm,
            success: res => {
              if (res.code == 200) {
                _this.$message({ type: "success", message: _this.$t('operateSuccessfully') });
                _this.getBusinessList();
                _this.editRoleDrawer = false;
              }
              else {
                _this.$message({ type: "warning", message: _this.$t(res.msg) });
              }
              _this.isloading = false;
            }
          })
        }
      })
    },
  },
  created () { },
  mounted () {
    let _this = this;
    this.$nextTick(() => {
      let queryTableHeight = _this.$refs.pageHead.offsetHeight + 15;
      _this.queryTableHeight = queryTableHeight + 'px';
      _this.getBusinessList();
    })
  },
}
</script>
<style lang="less" scoped>
.tree-card {
  height: 300px;
}
</style>